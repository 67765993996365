<template>
  <div>
    <v-form>
      <h2 class="mb-5">Identifying an Unknown Acid - Report Sheet</h2>

      <p class="mb-3">a) What was the unknown code for your acid sample?</p>

      <v-textarea
        v-model="inputs.unknownCode"
        class="mb-3"
        outlined
        rows="1"
        row-height="1"
        no-resize
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) Fill out the following table with the results from your experiment.</p>

      <v-simple-table class="mb-3 pl-3">
        <thead>
          <tr>
            <td><stemble-latex content="$\text{Property}$" /></td>
            <td style="text-align: center; font-weight: bold">Titration 1</td>
            <td style="text-align: center; font-weight: bold">Titration 2</td>
            <td style="text-align: center; font-weight: bold">Titration 3</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Concentration of Acid (M)</td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.concAcid1"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.concAcid2"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.concAcid3"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{pK}_\text{a}$" /> of unknown acid</td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.pKa1"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.pKa2"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.pKa3"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{K}_\text{a}$" /> of unknown acid</td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.Ka1"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.Ka2"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input">
              <calculation-input
                v-model="inputs.Ka3"
                class="my-2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">
        c) What is the average <stemble-latex content="$\text{K}_\text{a}$" /> value for your
        unknown acid?
      </p>

      <calculation-input
        v-model="inputs.avgKa"
        prepend-text="$\text{K}_\text{a}$"
        class="mb-5"
        :show-scientific-notation="false"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        d) Based on the <stemble-latex content="$\text{K}_\text{a}$" /> you just calculated and the
        master inventory, what is the identity of your acid? Justify your answer.
      </p>

      <v-textarea v-model="inputs.acidIdentityWritten" full-width rows="5" outlined auto-grow />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'OleMiss_IdentifyingUnknownAcidW2_Results',
  components: {STextarea, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        unknownCode: null,
        concAcid1: null,
        concAcid2: null,
        concAcid3: null,
        pKa1: null,
        pKa2: null,
        pKa3: null,
        Ka1: null,
        Ka2: null,
        Ka3: null,
        avgKa: null,
        acidIdentity: null,
        acidIdentityWritten: null,
      },
      options: [
        {
          text: 'Acetic Acid, $\\ce{CH3COOH}$',
          value: 'acetic',
        },
        {
          text: 'Carbonic Acid, $\\ce{H2CO3}',
          value: 'carbonic',
        },
        {
          text: 'Chloroacetic Acid, $\\ce{ClCH2COOH}',
          value: 'chloroacetic',
        },
        {
          text: 'Citric Acid, $\\ce{HOC(COOH)(CH2COOH)2}',
          value: 'citric',
        },
        {
          text: 'Hydrochloric acid, $\\ce{HCl}',
          value: 'hydrochloric',
        },
        {
          text: 'Hypochlorous acid, $\\ce{HOCl}',
          value: 'hypochlorous',
        },
        {
          text: 'Nitrous acid, $\\ce{HNO2}',
          value: 'nitrous',
        },
        {
          text: 'Sulfuric acid, $\\ce{H2SO4}',
          value: 'sulfuric',
        },
      ],
    };
  },
};
</script>
